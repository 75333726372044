import API from '../plugins/http';
import Utils from '../plugins/util';

const smartlibReport = {
  namespaced: true,
  state: {
    totalRows: 0,
    isLoading: false,
    isError: false,
    items: [],
    item: {},
    errorMessage: '',
    successMessage: '',
    selfVendorId: 0,
    isDownloadLoading: false,
  },
  getters: {},
  actions: {
    async fetchReportSmartlib({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(`report-smartlib`, { params: props });
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setSelfVendorId', response.data.data.selfVendorId);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchReportSmartlibById({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `report-smartlib/${props.id}?page=${props.page}&limit=${props.limit}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchReportSmartlibDetailById({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `report-smartlib/${props.id}/detail/${props.detailId}`,
        );
        const data = response.data.data;
        commit('setItem', data);
        commit('setLoading', false);
        return data;
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response.status == 404) {
          commit('setErrorMessage', 'report smartlib detail not found');
        } else {
          commit('setErrorMessage', 'Internal Server Error');
        }
      }
    },
    async searchReportSmartlibDetail({ commit }, props) {
      commit('setError', false);
      try {
        const q = Utils.encodeUrlWithBase64(props.q);
        const currentPage = props?.page ?? 1;
        const currentLimit = props?.limit ?? 20;
        const by = props?.by ?? 'DESC';
        const response = await API.get(
          `report-smartlib/${props.id}/detail/search/${q}?page=${currentPage}&limit=${currentLimit}&by=${by}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async downloadReportSmartlibIntoEmailPublisher({ commit }, props) {
      commit('setDownloadLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post(`download-invoice/smartlib`, props);
        let result = 'Unknown status';
        if (response?.status == 200) {
          result = response.data.message
        }
        commit('setSuccessMessage', result);
        commit('setDownloadLoading', false);
      } catch (error) {
        let errMsg = 'Error, please try again';
        const response = error?.response;
        if (response?.status == 404) {
          errMsg = response?.statusText;
        }
        if (response?.status == 422) {
          errMsg = response?.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response?.data?.errors ?? [])
            .map((item) => item?.param)
            .join(', ');
        }
        if ([500, 403].includes(response?.status)) {
          errMsg = response?.data?.data?.message ?? response?.statusText;
        }
        if (error?.data?.message) {
          errMsg = error?.data?.message;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setDownloadLoading', false);
      }
    },
    async getReportSmartlibTotalRevenue({ commit }, props) {
      commit('setLoading', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.post(
          `report-smartlib/${props.invoice_id}/revenue`,
          props,
        );
        let result = 'Unknown status';
        if (response?.status == 200) {
          result = response.data.data[0].sum;
          const formatIdr = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(result);
          return `Total Revenue: ${formatIdr}`;
        }
        return result;
      } catch (error) {
        let errMsg = 'Error, please try again';
        const response = error?.response;
        if (response?.status == 404) {
          errMsg = response?.statusText;
        }
        if (response?.status == 422) {
          errMsg = response?.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response?.data?.errors ?? [])
            .map((item) => item?.param)
            .join(', ');
        }
        if ([500, 403].includes(response?.status)) {
          errMsg = response?.data?.data?.message ?? response?.statusText;
        }
        if (error?.data?.message) {
          errMsg = error?.data?.message;
        }
        return errMsg;
      }
    },
  },
  mutations: {
    setItems(state, data) {
      state.items = data;
    },
    setItem(state, data) {
      state.item = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setSelfVendorId(state, data) {
      state.selfVendorId = data;
    },
    setDownloadLoading(state, data) {
      state.isDownloadLoading = data;
    },
  },
};

export default smartlibReport;