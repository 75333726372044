import Vue from 'vue';
import Vuex from 'vuex';
import accessCompany from './accessCompany';
import accessCustomerSupport from './accessCustomerSupport';
import accessPermission from './accessPermission';
import accessPublisher from './accessPublisher';
import accessRole from './accessRole';
import accessUserEbook from './accessUserEbook';
import announcement from './announcement';
import profile from './profile';
import currency from './currency';
import ebooks from './ebooks';
import magazine from './magazine';
import orders from './orders';
import newspaper from './newspaper';
import discounts from './discounts';
import discountLog from './discountLog';
import currencyQuotes from './currencyQuotes';
import campaign from './campaign';
import offers from './offers';
import promotions from './promotions';
import single from './single';
import vendors from './vendors';
import vendorReportFormat from './vendorReportFormat';
import buffets from './buffets';
import ofscatalog from './ofscatalog';
import ofsorder from './ofsOrder';
import logOfs from './logOfs';
import brands from './brands';
import payments from './payments';
import offersSubscription from './offersSubscription';
import offersBundle from './offersBundle';
import offerspremium from './offerspremium';
import login from './login';
import forgetPassword from './forgetPassword';
import userChart from './userChart';
import users from './users';
import sales from './sales';
import subscription from './subscription';
import gramedia from './gramedia';
import auth from './auth';
import bundle from './bundle';
import subledger from './subledger';
import finance from './finance';
import dashboard from './dashboard';
import admin from './admin';
import ax from './ax';
import CorporateDashboard from './corporatedashboard';
import productChoice from './productChoices';
import ItemSoax from './itemax';
import tiercodeAndroid from './tiercodeAndroid';
import tiercodeIOS from './tiercodeIos';
import invalidTiercodeOffer from './invalidTiercodeOffer';
import itemcodeOffer from './itemcodeOffer';
import MonitoringItemUpload from './MonitoringItemUpload';
import authorPopular from './authorPopular';
import author from './author';
import narrator from './narrator';
import items from './items';
import So from './so';
import audioBooks from './audioBooks';
import bulkUpload from './bulkUpload';
/** Review */
import reviews from './reviews';
/** Organization */
import organization from './organization';
import category from './category';
import discount_code from './discount_code';
import category_bisac from './category_bisac';
import emailReport from './emailReport';
import banners from './banners';
import renewal from './renewal';
import menus from './menu';
import vendor_revenue from './vendor_revenueshares';
import content from './content';
import revenueshares from './revenueshares';
import refund from './refund';
import logSubledger from './logSubledger';
import client from './client';
import application from './application';
import Po from './po';
import brandAx from './brandAx';
import report from './report';
import financeReport from './financeReport';
import appstore from './appstore';
import vendorScoopint from './vendorScoopint';
import registerAx from './registerAx';
import dynamicPO from './dynamicPO';
import brandPremiumOffers from './brandPremiumOffers';
import dynamicSO from './dynamicSO';
import vendorPremium from './vendorPremium';
import ManageOtp from './manageOtp'
import log from './log';
import smartlibReport from './smartlibReport'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accessCompany: accessCompany,
    accessCustomerSupport: accessCustomerSupport,
    accessPermission: accessPermission,
    accessPublisher: accessPublisher,
    accessRole: accessRole,
    accessUserEbook: accessUserEbook,
    announcement: announcement,
    profile: profile,
    currency: currency,
    ebooks: ebooks,
    magazine: magazine,
    orders: orders,
    newspaper: newspaper,
    discounts: discounts,
    discountLog: discountLog,
    currencyQuotes: currencyQuotes,
    banners: banners,
    campaign: campaign,
    offers: offers,
    promotions: promotions,
    single: single,
    vendors: vendors,
    buffets: buffets,
    ofscatalog: ofscatalog,
    ofsorder: ofsorder,
    logOfs,
    brands: brands,
    brandPremiumOffers: brandPremiumOffers,
    bulkUpload: bulkUpload,
    payments: payments,
    offerspremium: offerspremium,
    offersSubscription: offersSubscription,
    offersBundle: offersBundle,
    login: login,
    forgetPassword: forgetPassword,
    userChart: userChart,
    users: users,
    sales: sales,
    subscription: subscription,
    gramedia: gramedia,
    auth: auth,
    bundle: bundle,
    subledger: subledger,
    finance: finance,
    dashboard: dashboard,
    admin: admin,
    ax: ax,
    corporatedashboard: CorporateDashboard,
    productChoices: productChoice,
    ItemSoax: ItemSoax,
    tiercodeAndroid: tiercodeAndroid,
    tiercodeIOS: tiercodeIOS,
    invalidTiercodeOffer: invalidTiercodeOffer,
    itemcodeOffer: itemcodeOffer,
    MonitoringItemUpload: MonitoringItemUpload,
    authorPopular: authorPopular,
    author: author,
    narrator: narrator,
    items: items,
    So: So,
    audioBooks: audioBooks,
    /** Review */
    reviews: reviews,
    /** Organization */
    organization: organization,
    category,
    discount_code,
    emailReport,
    category_bisac,
    renewal,
    menus,
    vendor_revenue,
    content,
    revenueshares,
    refund: refund,
    logSubledger,
    client,
    application,
    report: report,
    Po,
    brandAx,
    financeReport,
    appstore,
    vendorReportFormat,
    vendorScoopint,
    registerAx,
    dynamicPO,
    dynamicSO,
    vendorPremium,
    log,
    ManageOtp,
    smartlibReport
  },
  strict: 'development',
});
